.timeline {
    position: relative
}

.timeline li:before {
    content: "";
    position: absolute;
    inset-block-start: 4.625rem;
    inset-block-end: -3rem;
    width: 1px;
    border: 1px dashed var(--primary02);
    inset-inline-start: 20%;
    margin-inline-start:-1.5px}

.timeline>li {
    position: relative;
    min-height: 3.125rem;
    padding: .938rem 0
}

.timeline .timeline-time {
    position: absolute;
    inset-inline-start: 0;
    width: 18%;
    inset-block-start: 3.35rem
}

.timeline .timeline-time .date,.timeline .timeline-time .time {
    display: block;
    font-weight: 500
}

.timeline .timeline-time .date {
    line-height: 1rem;
    font-size: .6rem;
    margin-bottom: 0;
    color: var(--text-muted)
}

.timeline .timeline-time .time {
    line-height: 1.5rem;
    font-size: 1.125rem;
    color: var(--text-muted)
}

.timeline .timeline-icon {
    inset-inline-start: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    inset-block-start: 3.188rem
}

.timeline .timeline-icon a {
    width: .625rem;
    height: .625rem;
    display: inline-block;
    border-radius: 50%;
    background: rgb(var(--light-rgb));
    color: var(--primary-color);
    line-height: .625rem;
    font-size: .875rem;
    border: .188rem solid var(--primary05)
}

.timeline .timeline-body {
    box-shadow: 0 1px 1rem #0000000d;
    margin-inline-start:22%;margin-inline-end:18%;background: var(--custom-white);
    position: relative;
    padding: .875rem 1.25rem;
    border-radius: .5rem
}

.timeline .timeline-body>div+div {
    margin-block-start:.938rem}

.timeline-loadmore-container {
    margin-block-start:3.5rem;margin-block-end:1.5rem}

.timeline li:last-child:before {
    display: none
}



@media (max-width: 575.98px) {
    .timeline .timeline-icon a {
        display:none
    }

    .timeline li:before {
        display: none
    }

    .timeline-body .media {
        flex-direction: column
    }

    .timeline-body .media .main-img-user {
        margin-block-end:.625rem!important}

    .timeline .timeline-time {
        z-index: 99;
        width: 100%;
        inset-inline-end: .313rem!important;
        position: absolute;
        inset-block-start: 1.5rem!important
    }

    .timeline-main-content {
        flex-direction: column
    }

    .timeline .timeline-body {
        margin-inline-start:0;margin-inline-end:0;position: relative
    }

    .timeline-badge {
        position: absolute;
        inset-inline-start: 1.15rem;
        inset-block-start: .8rem
    }

    .timeline .timeline-time .date,.timeline .timeline-time .time {
        display: inline
    }

    .timeline .timeline-time .time {
        line-height: 1rem;
        font-size: .688rem;
        margin-inline-start:.313rem;margin-inline-end:.625rem;color: var(--text-muted)
    }
}
