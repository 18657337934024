
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition)
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
}

.progress-stacked>.progress {
    overflow: visible
}

.progress-stacked>.progress>.progress-bar {
    width: 100%
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none
    }
}


.progress {
    background-color: rgb(var(--light-rgb));
    height: .75rem;
    border-radius: .25rem;

    width: 100%;
}

.progress-stacked {
    background-color: rgb(var(--light-rgb))
}

.progress-bar {
    color: #fff;
    background-color: var(--primary-color)
}

@keyframes progress-animate {
    0% {
        width: 0
    }
}

.progress-animate {
    position: relative;
    border-radius: .625rem
}

.progress-animate .progress-bar {
    position: relative;
    border-radius: .625rem;
    animation: 2s progress-animate
}

.progress.progress-xs,.progress-stacked.progress-xs {
    height: .3125rem
}

.progress.progress-sm,.progress-stacked.progress-sm {
    height: .5rem
}

.progress.progress-lg,.progress-stacked.progress-lg {
    height: 1rem
}

.progress.progress-xl,.progress-stacked.progress-xl {
    height: 1.25rem
}

.progress-custom {
    overflow: visible;
    position: relative
}

.progress-custom .progress-bar {
    position: relative;
    overflow: visible
}

.progress-custom .progress-bar:after {
    content: "";
    width: 1.15rem;
    height: 1.15rem;
    border-radius: 50%;
    box-shadow: 0 .313rem .313rem var(--primary02);
    background: #fff;
    position: absolute;
    inset-inline-end: -.375rem;
    inset-block-start: -.3rem;
    border: .25rem solid var(--primary-color)
}

.progress-custom .progress-bar:before {
    content: "50%";
    width: 1.875rem;
    height: 1.25rem;
    line-height: 1.25rem;
    border-radius: .188rem;
    background: inherit;
    box-shadow: 0 .313rem .313rem #0006;
    font-size: .625rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    position: absolute;
    inset-block-end: 1.5rem;
    inset-inline-end: -.688rem
}

.progress-custom .progress-bar.bg-secondary:after {
    border: .25rem solid rgb(var(--secondary-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--secondary-rgb),.2)
}

.progress-custom .progress-bar.bg-warning:after {
    border: .25rem solid rgb(var(--warning-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--warning-rgb),.2)
}

.progress-custom .progress-bar.bg-info:after {
    border: .25rem solid rgb(var(--info-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--info-rgb),.2)
}

.progress-custom .progress-bar.bg-success:after {
    border: .25rem solid rgb(var(--success-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--success-rgb),.2)
}

.progress-custom .progress-bar.bg-danger:after {
    border: .25rem solid rgb(var(--danger-rgb));
    box-shadow: 0 .313rem .313rem rgba(var(--danger-rgb),.2)
}

.progress-custom .progress-bar-title {
    padding: .313rem .625rem;
    margin: 0;
    background: var(--primary-color);
    border-radius: .313rem 0 0 .313rem;
    font-size: .625rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    inset-block-start: -.375rem;
    inset-inline-start: 0;
    z-index: 1
}

.progress-custom .progress-bar-title:after {
    content: "";
    border-inline-start:1.063rem solid var(--primary-color);border-block-start: .688rem solid transparent;
    border-block-end:.688rem solid transparent;position: absolute;
    inset-block-start: 0;
    inset-inline-end: -1.063rem
}

.progress-custom .progress-bar-title.bg-secondary {
    background-color: rgb(var(--secondary-rgb))
}

.progress-custom .progress-bar-title.bg-secondary:after {
    border-inline-start:1.063rem solid rgb(var(--secondary-rgb))}

.progress-custom .progress-bar-title.bg-success {
    background-color: rgb(var(--success-rgb))
}

.progress-custom .progress-bar-title.bg-success:after {
    border-inline-start:1.063rem solid rgb(var(--success-rgb))}

.progress-custom .progress-bar-title.bg-warning {
    background-color: rgb(var(--warning-rgb))
}

.progress-custom .progress-bar-title.bg-warning:after {
    border-inline-start:1.063rem solid rgb(var(--warning-rgb))}

.progress-custom .progress-bar-title.bg-danger {
    background-color: rgb(var(--danger-rgb))
}

.progress-custom .progress-bar-title.bg-danger:after {
    border-inline-start:1.063rem solid rgb(var(--danger-rgb))}

.progress-custom .progress-bar-title.bg-info {
    background-color: rgb(var(--info-rgb))
}

.progress-custom .progress-bar-title.bg-info:after {
    border-inline-start:1.063rem solid rgb(var(--info-rgb))}

.progress-custom .progress-bar-value {
    width: 1.875rem;
    height: 1.25rem;
    line-height: 1.25rem;
    border-radius: .188rem;
    background: var(--primary-color);
    box-shadow: 0 .313rem .313rem #0006;
    font-size: .625rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    position: absolute;
    inset-block-end: 1.5rem;
    inset-inline-end: -.688rem
}

.progress-custom .progress-bar-value:after {
    content: "";
    border-block-start:.438rem solid var(--primary-color);border-inline-start: .438rem solid transparent;
    border-inline-end:.438rem solid transparent;position: absolute;
    inset-block-end: -.375rem;
    inset-inline-start: 28%
}

.progress-custom .progress-bar-value.bg-secondary {
    background-color: rgb(var(--secondary-rgb))
}

.progress-custom .progress-bar-value.bg-secondary:after {
    border-block-start:.438rem solid rgb(var(--secondary-rgb))}

.progress-custom .progress-bar-value.bg-success {
    background-color: rgb(var(--success-rgb))
}

.progress-custom .progress-bar-value.bg-success:after {
    border-block-start:.438rem solid rgb(var(--success-rgb))}

.progress-custom .progress-bar-value.bg-warning {
    background-color: rgb(var(--warning-rgb))
}

.progress-custom .progress-bar-value.bg-warning:after {
    border-block-start:.438rem solid rgb(var(--warning-rgb))}

.progress-custom .progress-bar-value.bg-danger {
    background-color: rgb(var(--danger-rgb))
}

.progress-custom .progress-bar-value.bg-danger:after {
    border-block-start:.438rem solid rgb(var(--danger-rgb))}

.progress-custom .progress-bar-value.bg-info {
    background-color: rgb(var(--info-rgb))
}

.progress-custom .progress-bar-value.bg-info:after {
    border-block-start:.438rem solid rgb(var(--info-rgb))}

[dir=rtl] .progress-bar-title {
    border-radius: 0 .313rem .313rem 0
}

.progress-item-1,.progress-item-2,.progress-item-3 {
    position: absolute;
    margin-block-start:-.05rem;z-index: 1;
    height: .938rem;
    width: .938rem;
    border-radius: 1.563rem;
    background-color: rgb(var(--light-rgb))
}

.progress-item-1 {
    inset-inline-start: 25%
}

.progress-item-2 {
    inset-inline-start: 50%
}

.progress-item-3 {
    inset-inline-start: 75%
}

.custom-progress-3 {
    overflow: visible;
    border-radius: .625rem
}

.custom-progress-3 .progress-bar {
    position: relative;
    overflow: visible;
    margin: .313rem;
    border-radius: .25rem
}

.custom-progress-3 .progress-bar:before {
    content: "";
    position: absolute;
    width: .25rem;
    height: .25rem;
    inset-inline-start: .125rem;
    background-color: var(--custom-white);
    border-radius: 50%
}

.custom-progress-3 .progress-bar .progress-bar-value {
    border: .125rem solid var(--primary-color);
    width: 2.5rem;
    height: 1.563rem;
    line-height: 1.313rem;
    border-radius: 3.125rem;
    background: var(--custom-white);
    font-size: .625rem;
    font-weight: 600;
    color: var(--primary-color);
    text-align: center;
    position: absolute;
    inset-inline-end: -.625rem
}

.custom-progress-3 .progress-bar .progress-bar-value.secondary {
    border: .125rem solid rgb(var(--secondary-rgb));
    color: rgb(var(--secondary-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.warning {
    border: .125rem solid rgb(var(--warning-rgb));
    color: rgb(var(--warning-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.info {
    border: .125rem solid rgb(var(--info-rgb));
    color: rgb(var(--info-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.success {
    border: .125rem solid rgb(var(--success-rgb));
    color: rgb(var(--success-rgb))
}

.custom-progress-3 .progress-bar .progress-bar-value.danger {
    border: .125rem solid rgb(var(--danger-rgb));
    color: rgb(var(--danger-rgb))
}

.custom-progress-4.progress {
    background-color: var(--primary01);
    border-radius: .25rem
}

.custom-progress-4.progress.secondary {
    background-color: rgba(var(--secondary-rgb),.1)
}

.custom-progress-4.progress.secondary .progress-bar-label {
    color: rgb(var(--secondary-rgb))
}

.custom-progress-4.progress.warning {
    background-color: rgba(var(--warning-rgb),.1)
}

.custom-progress-4.progress.warning .progress-bar-label {
    color: rgb(var(--warning-rgb))
}

.custom-progress-4.progress.info {
    background-color: rgba(var(--info-rgb),.1)
}

.custom-progress-4.progress.info .progress-bar-label {
    color: rgb(var(--info-rgb))
}

.custom-progress-4.progress.success {
    background-color: rgba(var(--success-rgb),.1)
}

.custom-progress-4.progress.success .progress-bar-label {
    color: rgb(var(--success-rgb))
}

.custom-progress-4.progress.danger {
    background-color: rgba(var(--danger-rgb),.1)
}

.custom-progress-4.progress.danger .progress-bar-label {
    color: rgb(var(--danger-rgb))
}

.custom-progress-4.progress .progress-bar {
    border-radius: .25rem
}

.custom-progress-4.progress .progress-bar-label {
    position: absolute;
    inset-inline-end: .313rem;
    font-weight: 600;
    font-size: .813rem;
    color: var(--primary-color)
}
