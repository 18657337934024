
.table {
    color: var(--default-text-color);
    border-color: var(--default-border);
    margin-block-end:0}

.table tbody tr th {
    font-weight: 500
}

.table th,.table td {
    padding: .75rem;
    vertical-align: middle;
    line-height: 1.462;
    font-size: .813rem;
    font-weight: 500
}

.table thead tr th {
    font-weight: 600;
    font-size: .85rem
}

.table.table-sm>:not(caption)>*>* {
    padding: .3rem
}

.table.table-dark {
    color: var(--white-7);
    border-color: #ffffff1a
}

.table.table-primary {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--primary-rgb), .1);
    --bs-table-border-color: rgba(var(--primary-rgb), .1);
    --bs-table-striped-bg: rgba(var(--primary-rgb), .2);
    --bs-table-active-bg: rgba(var(--primary-rgb), .2);
    --bs-table-hover-bg: rgba(var(--primary-rgb), .2)
}

.table.table-primary>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--primary-rgb), .1);
    color: #000
}

.table.table-primary.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--primary-rgb), .2);
    color: #000
}

.table.table-primary.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--primary-rgb), .4);
    color: #fff
}

.table.table-primary .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--primary-rgb));
    color: #fff
}

.table.table-secondary {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--secondary-rgb), .1);
    --bs-table-border-color: rgba(var(--secondary-rgb), .1);
    --bs-table-striped-bg: rgba(var(--secondary-rgb), .2);
    --bs-table-active-bg: rgba(var(--secondary-rgb), .2);
    --bs-table-hover-bg: rgba(var(--secondary-rgb), .2)
}

.table.table-secondary>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--secondary-rgb), .1);
    color: #000
}

.table.table-secondary.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--secondary-rgb), .2);
    color: #000
}

.table.table-secondary.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--secondary-rgb), .4);
    color: #fff
}

.table.table-secondary .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--secondary-rgb));
    color: #fff
}

.table.table-warning {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--warning-rgb), .1);
    --bs-table-border-color: rgba(var(--warning-rgb), .1);
    --bs-table-striped-bg: rgba(var(--warning-rgb), .2);
    --bs-table-active-bg: rgba(var(--warning-rgb), .2);
    --bs-table-hover-bg: rgba(var(--warning-rgb), .2)
}

.table.table-warning>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--warning-rgb), .1);
    color: #000
}

.table.table-warning.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--warning-rgb), .2);
    color: #000
}

.table.table-warning.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--warning-rgb), .4);
    color: #fff
}

.table.table-warning .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--warning-rgb));
    color: #fff
}

.table.table-success {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--success-rgb), .1);
    --bs-table-border-color: rgba(var(--success-rgb), .1);
    --bs-table-striped-bg: rgba(var(--success-rgb), .2);
    --bs-table-active-bg: rgba(var(--success-rgb), .2);
    --bs-table-hover-bg: rgba(var(--success-rgb), .2)
}

.table.table-success>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--success-rgb), .1);
    color: #000
}

.table.table-success.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--success-rgb), .2);
    color: #000
}

.table.table-success.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--success-rgb), .4);
    color: #fff
}

.table.table-success .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--success-rgb));
    color: #fff
}

.table.table-danger {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--danger-rgb), .1);
    --bs-table-border-color: rgba(var(--danger-rgb), .1);
    --bs-table-striped-bg: rgba(var(--danger-rgb), .2);
    --bs-table-active-bg: rgba(var(--danger-rgb), .2);
    --bs-table-hover-bg: rgba(var(--danger-rgb), .2)
}

.table.table-danger>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--danger-rgb), .1);
    color: #000
}

.table.table-danger.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--danger-rgb), .2);
    color: #000
}

.table.table-danger.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--danger-rgb), .4);
    color: #fff
}

.table.table-danger .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--danger-rgb));
    color: #fff
}

.table.table-info {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--info-rgb), .1);
    --bs-table-border-color: rgba(var(--info-rgb), .1);
    --bs-table-striped-bg: rgba(var(--info-rgb), .2);
    --bs-table-active-bg: rgba(var(--info-rgb), .2);
    --bs-table-hover-bg: rgba(var(--info-rgb), .2)
}

.table.table-info>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--info-rgb), .1);
    color: #000
}

.table.table-info.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--info-rgb), .2);
    color: #000
}

.table.table-info.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--info-rgb), .4);
    color: #fff
}

.table.table-info .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--info-rgb));
    color: #fff
}

.table.table-light {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--light-rgb), .1);
    --bs-table-border-color: rgba(var(--light-rgb), .1);
    --bs-table-striped-bg: rgba(var(--light-rgb), .2);
    --bs-table-active-bg: rgba(var(--light-rgb), .2);
    --bs-table-hover-bg: rgba(var(--light-rgb), .2)
}

.table.table-light>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--light-rgb), .1);
    color: #000
}

.table.table-light.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--light-rgb), .2);
    color: #000
}

.table.table-light.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--light-rgb), .4);
    color: #fff
}

.table.table-light .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--light-rgb));
    color: #fff
}

.table.table-dark {
    background-color: #fff;
    color: #000;
    border-color: var(--bs-table-border-color);
    --bs-table-bg: rgba(var(--dark-rgb), .1);
    --bs-table-border-color: rgba(var(--dark-rgb), .1);
    --bs-table-striped-bg: rgba(var(--dark-rgb), .2);
    --bs-table-active-bg: rgba(var(--dark-rgb), .2);
    --bs-table-hover-bg: rgba(var(--dark-rgb), .2)
}

.table.table-dark>:not(caption)>*>* {
    --bs-table-bg: rgba(var(--dark-rgb), .1);
    color: #000
}

.table.table-dark.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--dark-rgb), .2);
    color: #000
}

.table.table-dark.table.table-hover>tbody>tr:hover>* {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgba(var(--dark-rgb), .4);
    color: #fff
}

.table.table-dark .table-active {
    background-color: var(--bs-table-accent-bg);
    --bs-table-accent-bg: rgb(var(--dark-rgb));
    color: #fff
}

.table.table-bordered.border-primary tbody,.table.table-bordered.border-primary td,.table.table-bordered.border-primary tfoot,.table.table-bordered.border-primary th,.table.table-bordered.border-primary thead,.table.table-bordered.border-primary tr {
    border-color: rgba(var(--primary-rgb),.1)
}

.table.table-bordered.border-info tbody,.table.table-bordered.border-info td,.table.table-bordered.border-info tfoot,.table.table-bordered.border-info th,.table.table-bordered.border-info thead,.table.table-bordered.border-info tr {
    border-color: rgba(var(--info-rgb),.1)
}

.table.table-bordered.border-secondary tbody,.table.table-bordered.border-secondary td,.table.table-bordered.border-secondary tfoot,.table.table-bordered.border-secondary th,.table.table-bordered.border-secondary thead,.table.table-bordered.border-secondary tr {
    border-color: rgba(var(--secondary-rgb),.1)
}

.table.table-bordered.border-warning tbody,.table.table-bordered.border-warning td,.table.table-bordered.border-warning tfoot,.table.table-bordered.border-warning th,.table.table-bordered.border-warning thead,.table.table-bordered.border-warning tr {
    border-color: rgba(var(--warning-rgb),.1)
}

.table.table-bordered.border-success tbody,.table.table-bordered.border-success td,.table.table-bordered.border-success tfoot,.table.table-bordered.border-success th,.table.table-bordered.border-success thead,.table.table-bordered.border-success tr {
    border-color: rgba(var(--success-rgb),.1)
}

.table.table-bordered.border-danger tbody,.table.table-bordered.border-danger td,.table.table-bordered.border-danger tfoot,.table.table-bordered.border-danger th,.table.table-bordered.border-danger thead,.table.table-bordered.border-danger tr {
    border-color: rgba(var(--danger-rgb),.1)
}

.table.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgba(var(--dark-rgb), .0125);
    color: var(--default-text-color)
}

.table.table-striped-columns>:not(caption)>tr>:nth-child(2n) {
    --bs-table-accent-bg: rgba(var(--dark-rgb), .025);
    color: var(--default-text-color)
}

.table tbody.table-group-divider {
    border-top: 1px solid var(--default-border)
}

.table.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--default-background);
    --bs-table-bg-state: var(--default-background);
    color: var(--default-text-color)
}

.table .table-active {
    --bs-table-accent-bg: rgb(var(--light-rgb));
    color: var(--default-text-color)
}

.table thead.table-primary {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--primary-rgb), .1);
    --bs-table-border-color: rgba(var(--primary-rgb), .1);
    --bs-table-striped-bg: rgba(var(--primary-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--primary-rgb), .5);
    --bs-table-hover-bg: rgba(var(--primary-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-secondary {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--secondary-rgb), .1);
    --bs-table-border-color: rgba(var(--secondary-rgb), .1);
    --bs-table-striped-bg: rgba(var(--secondary-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--secondary-rgb), .5);
    --bs-table-hover-bg: rgba(var(--secondary-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-warning {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--warning-rgb), .1);
    --bs-table-border-color: rgba(var(--warning-rgb), .1);
    --bs-table-striped-bg: rgba(var(--warning-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--warning-rgb), .5);
    --bs-table-hover-bg: rgba(var(--warning-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-success {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--success-rgb), .1);
    --bs-table-border-color: rgba(var(--success-rgb), .1);
    --bs-table-striped-bg: rgba(var(--success-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--success-rgb), .5);
    --bs-table-hover-bg: rgba(var(--success-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-danger {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--danger-rgb), .1);
    --bs-table-border-color: rgba(var(--danger-rgb), .1);
    --bs-table-striped-bg: rgba(var(--danger-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--danger-rgb), .5);
    --bs-table-hover-bg: rgba(var(--danger-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-info {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--info-rgb), .1);
    --bs-table-border-color: rgba(var(--info-rgb), .1);
    --bs-table-striped-bg: rgba(var(--info-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--info-rgb), .5);
    --bs-table-hover-bg: rgba(var(--info-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-light {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--light-rgb), .1);
    --bs-table-border-color: rgba(var(--light-rgb), .1);
    --bs-table-striped-bg: rgba(var(--light-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--light-rgb), .5);
    --bs-table-hover-bg: rgba(var(--light-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table thead.table-dark {
    background-color: #fff;
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--dark-rgb), .1);
    --bs-table-border-color: rgba(var(--dark-rgb), .1);
    --bs-table-striped-bg: rgba(var(--dark-rgb), .5);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--dark-rgb), .5);
    --bs-table-hover-bg: rgba(var(--dark-rgb), .5);
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color)
}

.table-primary {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--primary-rgb), .1);
    --bs-table-border-color: rgba(var(--primary-rgb), .1);
    --bs-table-striped-bg: rgba(var(--primary-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--primary-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--primary-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--primary-rgb),.1);
    background-color: #fff
}

.table-secondary {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--secondary-rgb), .1);
    --bs-table-border-color: rgba(var(--secondary-rgb), .1);
    --bs-table-striped-bg: rgba(var(--secondary-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--secondary-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--secondary-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--secondary-rgb),.1);
    background-color: #fff
}

.table-warning {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--warning-rgb), .1);
    --bs-table-border-color: rgba(var(--warning-rgb), .1);
    --bs-table-striped-bg: rgba(var(--warning-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--warning-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--warning-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--warning-rgb),.1);
    background-color: #fff
}

.table-success {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--success-rgb), .1);
    --bs-table-border-color: rgba(var(--success-rgb), .1);
    --bs-table-striped-bg: rgba(var(--success-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--success-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--success-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--success-rgb),.1);
    background-color: #fff
}

.table-info {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--info-rgb), .1);
    --bs-table-border-color: rgba(var(--info-rgb), .1);
    --bs-table-striped-bg: rgba(var(--info-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--info-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--info-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--info-rgb),.1);
    background-color: #fff
}

.table-danger {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--danger-rgb), .1);
    --bs-table-border-color: rgba(var(--danger-rgb), .1);
    --bs-table-striped-bg: rgba(var(--danger-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--danger-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--danger-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--danger-rgb),.1);
    background-color: #fff
}

.table-dark {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--dark-rgb), .1);
    --bs-table-border-color: rgba(var(--dark-rgb), .1);
    --bs-table-striped-bg: rgba(var(--dark-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--dark-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--dark-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--dark-rgb),.1);
    background-color: #fff
}

.table-light {
    --bs-table-color: #000;
    --bs-table-bg: rgba(var(--light-rgb), .1);
    --bs-table-border-color: rgba(var(--light-rgb), .1);
    --bs-table-striped-bg: rgba(var(--light-rgb), .3);
    --bs-table-striped-color: #000;
    --bs-table-active-bg: rgba(var(--light-rgb), .3);
    --bs-table-active-color: #000;
    --bs-table-hover-bg: rgba(var(--light-rgb), .3);
    --bs-table-hover-color: #000;
    color: #000;
    border-color: rgba(var(--light-rgb),.1);
    background-color: #fff
}

[data-theme-mode=dark] .table-dark {
    border-color: #00000006
}

[data-theme-mode=dark] div.dtr-modal div.dtr-modal-display {
    background-color: var(--custom-white)
}

[dir=rtl] .table {
    margin-inline-end:1px}
