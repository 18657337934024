
.btn-icon {
    width: 2.313rem;
    height: 2.313rem;
    font-size: .95rem;
    flex-shrink: 0
}

.btn-icon i {
    padding: 0rem;
    margin: 0 -.5rem
}

.btn-icon.btn-sm {
    width: 1.75rem;
    height: 1.75rem;
    font-size: .8rem
}

.btn-icon.btn-lg {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.2rem
}

.btn-list button,.btn-list div,.btn-list a,.btn-list input {
    margin: 0 .375rem .375rem 0
}

[dir=rtl] .btn-list button,[dir=rtl] .btn-list div,[dir=rtl] .btn-list a,[dir=rtl] .btn-list input {
    margin: 0 0 .375rem .375rem
}

.btn-list {
    margin-block-end:-.5rem}

.btn-bd-primary {
    --bs-btn-font-weight: 600;
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-border-radius: .5rem;
    --bs-btn-hover-color: var(--primary-color);
    --bs-btn-hover-bg: rgba(var(--primary-color), .9);
    --bs-btn-hover-border-color: rgba(var(--primary-color), .9);
    --bs-btn-active-color: var(--primary-color);
    --bs-btn-active-bg: rgba(var(--primary-color), .9);
    --bs-btn-active-border-color: rgba(var(--primary-color), .9)
}

.btn-check:active+.btn-outline-primary,.btn-check:checked+.btn-outline-primary,.btn-outline-primary.active,.btn-outline-primary.dropdown-toggle.show,.btn-outline-primary:active,.btn-check+.btn.btn-outline-primary:hover {
    color: #fff!important;
    background-color: rgb(var(--primary-rgb))!important;
    border-color: rgb(var(--primary-rgb))!important
}

.btn-check:active+.btn-outline-secondary,.btn-check:checked+.btn-outline-secondary,.btn-outline-secondary.active,.btn-outline-secondary.dropdown-toggle.show,.btn-outline-secondary:active,.btn-check+.btn.btn-outline-secondary:hover {
    color: #fff!important;
    background-color: rgb(var(--secondary-rgb))!important;
    border-color: rgb(var(--secondary-rgb))!important
}

.btn-check:active+.btn-outline-info,.btn-check:checked+.btn-outline-info,.btn-outline-info.active,.btn-outline-info.dropdown-toggle.show,.btn-outline-info:active,.btn-check+.btn.btn-outline-info:hover {
    color: #fff!important;
    background-color: rgb(var(--info-rgb))!important;
    border-color: rgb(var(--info-rgb))!important
}

.btn-check:active+.btn-outline-warning,.btn-check:checked+.btn-outline-warning,.btn-outline-warning.active,.btn-outline-warning.dropdown-toggle.show,.btn-outline-warning:active,.btn-check+.btn.btn-outline-warning:hover {
    color: #fff!important;
    background-color: rgb(var(--warning-rgb))!important;
    border-color: rgb(var(--warning-rgb))!important
}

.btn-check:active+.btn-outline-danger,.btn-check:checked+.btn-outline-danger,.btn-outline-danger.active,.btn-outline-danger.dropdown-toggle.show,.btn-outline-danger:active,.btn-check+.btn.btn-outline-danger:hover {
    color: #fff!important;
    background-color: rgb(var(--danger-rgb))!important;
    border-color: rgb(var(--danger-rgb))!important
}

.btn-check:active+.btn-outline-success,.btn-check:checked+.btn-outline-success,.btn-outline-success.active,.btn-outline-success.dropdown-toggle.show,.btn-outline-success:active,.btn-check+.btn.btn-outline-success:hover {
    color: #fff!important;
    background-color: rgb(var(--success-rgb))!important;
    border-color: rgb(var(--success-rgb))!important
}

.btn-check:active+.btn-outline-light,.btn-check:checked+.btn-outline-light,.btn-outline-light.active,.btn-outline-light.dropdown-toggle.show,.btn-outline-light:active,.btn-outline-light:hover,.btn-check+.btn.btn-outline-light:hover {
    color: var(--default-text-color)!important;
    background-color: rgb(var(--light-rgb))!important;
    border-color: rgb(var(--light-rgb))!important
}

.btn-check:active+.btn-outline-dark,.btn-check:checked+.btn-outline-dark,.btn-outline-dark.active,.btn-outline-dark.dropdown-toggle.show,.btn-outline-dark:active,.btn-check+.btn.btn-outline-dark:hover {
    color: var(--custom-white)!important;
    background-color: var(--custom-black)!important;
    border-color: var(--custom-black)!important
}

.btn-check:active+.btn-outline-teal,.btn-check:checked+.btn-outline-teal,.btn-outline-teal.active,.btn-outline-teal.dropdown-toggle.show,.btn-outline-teal:active,.btn-check+.btn.btn-outline-teal:hover {
    color: #fff!important;
    background-color: rgb(var(--teal-rgb))!important;
    border-color: rgb(var(--teal-rgb))!important
}

.btn-check:checked+.btn-outline-primary:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--primary-color),.5)
}

.btn-check:checked+.btn-outline-secondary:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb),.5)
}

.btn-check:checked+.btn-outline-success:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--success-rgb),.5)
}

.btn-check:checked+.btn-outline-warning:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--warning-rgb),.5)
}

.btn-check:checked+.btn-outline-info:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--info-rgb),.5)
}

.btn-check:checked+.btn-outline-danger:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--danger-rgb),.5)
}

.btn-check:checked+.btn-outline-light:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--light-rgb),.5)
}

.btn-check:checked+.btn-outline-dark:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--dark-rgb),.5)
}

.btn-check:checked+.btn-outline-teal:focus {
    box-shadow: 0 .25rem 1rem rgba(var(--teal-rgb),.5)
}

.btn-check:focus+.btn.btn-outline-primary {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: none
}

.btn-check:checked+.btn.btn-outline-secondary {
    color: #fff;
    background-color: rgb(var(--secondary-rgb));
    border-color: rgb(var(--secondary-rgb));
    box-shadow: 0 .25rem 1rem rgba(var(--secondary-rgb),.3)
}

.btn.show.btn-outline-secondary {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-color: rgb(var(--secondary-rgb))
}

.btn.btn-primary-light {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.btn.btn-primary-light:hover,.btn.btn-primary-light:focus,.btn.btn-primary-light:active {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color)
}

.btn.btn-secondary-light {
    background-color: rgba(var(--secondary-rgb),.1);
    color: rgb(var(--secondary-rgb))
}

.btn.btn-secondary-light:hover,.btn.btn-secondary-light:focus,.btn.btn-secondary-light:active {
    background-color: rgb(var(--secondary-rgb));
    color: #fff;
    border-color: rgb(var(--secondary-rgb))
}

.btn.btn-warning-light {
    background-color: rgba(var(--warning-rgb),.1);
    color: rgb(var(--warning-rgb))
}

.btn.btn-warning-light:hover,.btn.btn-warning-light:focus,.btn.btn-warning-light:active {
    background-color: rgb(var(--warning-rgb));
    color: #fff;
    border-color: rgb(var(--warning-rgb))
}

.btn.btn-info-light {
    background-color: rgba(var(--info-rgb),.1);
    color: rgb(var(--info-rgb))
}

.btn.btn-info-light:hover,.btn.btn-info-light:focus,.btn.btn-info-light:active {
    background-color: rgb(var(--info-rgb));
    color: #fff;
    border-color: rgb(var(--info-rgb))
}

.btn.btn-success-light {
    background-color: rgba(var(--success-rgb),.1);
    color: rgb(var(--success-rgb))
}

.btn.btn-success-light:hover,.btn.btn-success-light:focus,.btn.btn-success-light:active {
    background-color: rgb(var(--success-rgb));
    color: #fff;
    border-color: rgb(var(--success-rgb))
}

.btn.btn-danger-light {
    background-color: rgba(var(--danger-rgb),.1);
    color: rgb(var(--danger-rgb))
}

.btn.btn-danger-light:hover,.btn.btn-danger-light:focus,.btn.btn-danger-light:active {
    background-color: rgb(var(--danger-rgb));
    color: #fff;
    border-color: rgb(var(--danger-rgb))
}

.btn.btn-purple-light {
    background-color: rgba(var(--purple-rgb),.1);
    color: rgb(var(--purple-rgb))
}

.btn.btn-purple-light:hover,.btn.btn-purple-light:focus,.btn.btn-purple-light:active {
    background-color: rgb(var(--purple-rgb));
    color: #fff;
    border-color: rgb(var(--purple-rgb))
}

.btn.btn-teal-light {
    background-color: rgba(var(--teal-rgb),.1);
    color: rgb(var(--teal-rgb))
}

.btn.btn-teal-light:hover,.btn.btn-teal-light:focus,.btn.btn-teal-light:active {
    background-color: rgb(var(--teal-rgb));
    color: #fff;
    border-color: rgb(var(--teal-rgb))
}

.btn.btn-orange-light {
    background-color: rgba(var(--orange-rgb),.1);
    color: rgb(var(--orange-rgb))
}

.btn.btn-orange-light:hover,.btn.btn-orange-light:focus,.btn.btn-orange-light:active {
    background-color: rgb(var(--orange-rgb));
    color: #fff;
    border-color: rgb(var(--orange-rgb))
}

.btn.btn-primary-gradient {
    background: linear-gradient(to right,var(--primary-color) 0%,#0086ED 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-primary-gradient:hover {
    border: 0
}

.btn.btn-secondary-gradient {
    background: linear-gradient(to right,rgb(var(--secondary-rgb)) 0%,#6789D8 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-secondary-gradient:hover {
    border: 0
}

.btn.btn-warning-gradient {
    background: linear-gradient(to right,rgb(var(--warning-rgb)) 0%,#7FA53A 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-warning-gradient:hover {
    border: 0
}

.btn.btn-info-gradient {
    background: linear-gradient(to right,rgb(var(--info-rgb)) 0%,#52F0CE 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-info-gradient:hover {
    border: 0
}

.btn.btn-success-gradient {
    background: linear-gradient(to right,rgb(var(--success-rgb)) 0%,#00A1C0 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-success-gradient:hover {
    border: 0
}

.btn.btn-danger-gradient {
    background: linear-gradient(to right,rgb(var(--danger-rgb)) 0%,#A34A88 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-danger-gradient:hover {
    border: 0
}

.btn.btn-orange-gradient {
    background: linear-gradient(to right,rgb(var(--orange-rgb)) 0%,#9BA815 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-orange-gradient:hover {
    border: 0
}

.btn.btn-teal-gradient {
    background: linear-gradient(to right,rgb(var(--teal-rgb)) 0%,#0695DD 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-teal-gradient:hover {
    border: 0
}

.btn.btn-purple-gradient {
    background: linear-gradient(to right,rgb(var(--purple-rgb)) 0%,#FF496D 100%);
    color: #fff!important;
    border: 0
}

.btn.btn-purple-gradient:hover {
    border: 0
}

.btn.btn-primary-ghost {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.btn.btn-primary-ghost:active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.btn.btn-secondary-ghost {
    background-color: transparent;
    color: rgb(var(--secondary-rgb));
    border: 1px solid rgb(var(--secondary-rgb))
}

.btn.btn-secondary-ghost:active {
    color: rgb(var(--secondary-rgb));
    border: 1px solid rgb(var(--secondary-rgb))
}

.btn.btn-warning-ghost {
    background-color: transparent;
    color: rgb(var(--warning-rgb));
    border: 1px solid rgb(var(--warning-rgb))
}

.btn.btn-warning-ghost:active {
    color: rgb(var(--warning-rgb));
    border: 1px solid rgb(var(--warning-rgb))
}

.btn.btn-info-ghost {
    background-color: transparent;
    color: rgb(var(--info-rgb));
    border: 1px solid rgb(var(--info-rgb))
}

.btn.btn-info-ghost:active {
    color: rgb(var(--info-rgb));
    border: 1px solid rgb(var(--info-rgb))
}

.btn.btn-success-ghost {
    background-color: transparent;
    color: rgb(var(--success-rgb));
    border: 1px solid rgb(var(--success-rgb))
}

.btn.btn-success-ghost:active {
    color: rgb(var(--success-rgb));
    border: 1px solid rgb(var(--success-rgb))
}

.btn.btn-danger-ghost {
    background-color: transparent;
    color: rgb(var(--danger-rgb));
    border: 1px solid rgb(var(--danger-rgb))
}

.btn.btn-danger-ghost:active {
    color: rgb(var(--danger-rgb));
    border: 1px solid rgb(var(--danger-rgb))
}

.btn.btn-orange-ghost {
    background-color: transparent;
    color: rgb(var(--orange-rgb));
    border: 1px solid rgb(var(--orange-rgb))
}

.btn.btn-orange-ghost:active {
    color: rgb(var(--orange-rgb));
    border: 1px solid rgb(var(--orange-rgb))
}

.btn.btn-teal-ghost {
    background-color: transparent;
    color: rgb(var(--teal-rgb));
    border: 1px solid rgb(var(--teal-rgb))
}

.btn.btn-teal-ghost:active {
    color: rgb(var(--teal-rgb));
    border: 1px solid rgb(var(--teal-rgb))
}

.btn.btn-purple-ghost {
    background-color: transparent;
    color: rgb(var(--purple-rgb));
    border: 1px solid rgb(var(--purple-rgb))
}

.btn.btn-purple-ghost:active {
    color: rgb(var(--purple-rgb));
    border: 1px solid rgb(var(--purple-rgb))
}

.btn.btn-w-xs {
    min-width: 5.625rem
}

.btn.btn-w-sm {
    min-width: 6.975rem
}

.btn.btn-w-md {
    min-width: 8.125rem
}

.btn.btn-w-lg {
    min-width: 9.375rem
}

.btn-loader {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center
}

.btn-loader .loading {
    margin-inline-end:.75rem}

.btn-loader i {
    animation: loader 1s infinite linear;
    position: absolute!important;
    top: .25rem
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.btn-raised-shadow:hover {
    box-shadow: 0 .5rem 1rem .5rem var(--black-1)
}

.label-btn {
    position: relative;
    padding-inline-start:2.6rem}

.label-btn-icon {
    position: absolute;
    width: 2.25rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    inset-inline-start: -1px;
    inset-block-start: -1px;
    inset-block-end: -1px;
    background-color: var(--white-2)
}

.label-btn.label-end {
    padding-inline-start:1rem;padding-inline-end:2.6rem}

.label-btn.label-end .label-btn-icon {
    inset-inline-end: -1px;
    inset-inline-start: auto
}

.custom-button {
    position: relative;
    padding-inline-start:2.75rem}

.custom-button .custom-btn-icons {
    box-shadow: 0 0 1px .25rem #0000001a;
    position: absolute;
    inset-inline-start: -.125rem;
    inset-block-start: 0px;
    background-color: var(--custom-white);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: .375rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    width: 2.25rem;
    height: 2.25rem
}

.custom-button .custom-btn-icons i {
    position: absolute
}

.btn-hover {
    position: relative
}

.btn-hover.btn-hover-animate {
    transition: all .2s linear 0s
}

.btn-hover.btn-hover-animate:before {
    content: "";
    font-family: bootstrap-icons!important;
    font-size: .8125rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset-inline-end: 0;
    inset-block-start: 0;
    opacity: 0;
    height: 100%;
    width: 2rem;
    transition: all .2s linear 0s
}

.btn-hover.btn-hover-animate:hover {
    padding-inline-end:2rem}

.btn-hover.btn-hover-animate:hover:before {
    opacity: 1;
    text-indent: 0
}

.btn-glare {
    border: 1px solid;
    overflow: hidden;
    position: relative
}

.btn-glare span {
    z-index: 20
}

.btn-glare:after {
    background: var(--custom-white);
    content: "";
    height: 4.6875rem;
    inset-inline-start: -1.625rem;
    opacity: .15;
    position: absolute;
    inset-block-start: -3.125rem;
    transform: rotate(35deg);
    transition: all .6s cubic-bezier(.2,1,.25,1);
    width: 1.875rem;
    z-index: 30
}

.btn-glare:hover:after {
    inset-inline-start: 100%;
    inset-block-start: 0;
    transition: all .6s cubic-bezier(.2,1,.25,1)
}

.btn-darken-hover {
    position: relative
}

.btn-darken-hover:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    inset-block-start: 0;
    inset-inline-start: 0
}

.btn-custom-border {
    border: 0;
    box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb),0);
    outline: 1px solid;
    outline-color: rgba(var(--warning-rgb),.5);
    outline-offset: 0;
    text-shadow: none;
    transition: all 1.25s cubic-bezier(.19,1,.22,1)
}

.btn-custom-border:hover {
    border: 0 solid;
    box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb),.5),0 0 1.25rem rgba(var(--warning-rgb),.2);
    outline-color: rgba(var(--warning-rgb),0);
    outline-offset: .938rem
}

.btn-border-down.btn-primary-light {
    border-block-end:.1875rem solid var(--primary-color)}

.btn-border-down.btn-secondary-light {
    border-block-end: .1875rem solid rgb(var(--secondary-rgb))
}

.btn-border-down.btn-info-light {
    border-block-end:.1875rem solid rgb(var(--info-rgb))}

.btn-border-down.btn-warning-light {
    border-block-end: .1875rem solid rgb(var(--warning-rgb))
}

.btn-border-down.btn-success-light {
    border-block-end:.1875rem solid rgb(var(--success-rgb))}

.btn-border-down.btn-danger-light {
    border-block-end: .1875rem solid rgb(var(--danger-rgb))
}

.btn-border-down.btn-dark-light {
    border-block-end:.1875rem solid var(--primary-color)}

.btn-border-down.btn-teal-light {
    border-block-end: .1875rem solid rgb(var(--teal-rgb))
}

.btn-border-down.btn-purple-light {
    border-block-end:.1875rem solid rgb(var(--purple-rgb))}

.btn-border-start.btn-primary-light {
    border-inline-start: .1875rem solid var(--primary-color)
}

.btn-border-start.btn-secondary-light {
    border-inline-start:.1875rem solid rgb(var(--secondary-rgb))}

.btn-border-start.btn-info-light {
    border-inline-start: .1875rem solid rgb(var(--info-rgb))
}

.btn-border-start.btn-warning-light {
    border-inline-start:.1875rem solid rgb(var(--warning-rgb))}

.btn-border-start.btn-success-light {
    border-inline-start: .1875rem solid rgb(var(--success-rgb))
}

.btn-border-start.btn-danger-light {
    border-inline-start:.1875rem solid rgb(var(--danger-rgb))}

.btn-border-start.btn-dark-light {
    border-inline-start: .1875rem solid var(--primary-color)
}

.btn-border-start.btn-teal-light {
    border-inline-start:.1875rem solid rgb(var(--teal-rgb))}

.btn-border-start.btn-purple-light {
    border-inline-start: .1875rem solid rgb(var(--purple-rgb))
}

.btn-border-end.btn-primary-light {
    border-inline-end:.1875rem solid var(--primary-color)}

.btn-border-end.btn-secondary-light {
    border-inline-end: .1875rem solid rgb(var(--secondary-rgb))
}

.btn-border-end.btn-info-light {
    border-inline-end:.1875rem solid rgb(var(--info-rgb))}

.btn-border-end.btn-warning-light {
    border-inline-end: .1875rem solid rgb(var(--warning-rgb))
}

.btn-border-end.btn-success-light {
    border-inline-end:.1875rem solid rgb(var(--success-rgb))}

.btn-border-end.btn-danger-light {
    border-inline-end: .1875rem solid rgb(var(--danger-rgb))
}

.btn-border-end.btn-dark-light {
    border-inline-end:.1875rem solid var(--primary-color)}

.btn-border-end.btn-teal-light {
    border-inline-end: .1875rem solid rgb(var(--teal-rgb))
}

.btn-border-end.btn-purple-light {
    border-inline-end:.1875rem solid rgb(var(--purple-rgb))}

.btn-border-top.btn-primary-light {
    border-block-start: .1875rem solid var(--primary-color)
}

.btn-border-top.btn-secondary-light {
    border-block-start:.1875rem solid rgb(var(--secondary-rgb))}

.btn-border-top.btn-info-light {
    border-block-start: .1875rem solid rgb(var(--info-rgb))
}

.btn-border-top.btn-warning-light {
    border-block-start:.1875rem solid rgb(var(--warning-rgb))}

.btn-border-top.btn-success-light {
    border-block-start: .1875rem solid rgb(var(--success-rgb))
}

.btn-border-top.btn-danger-light {
    border-block-start:.1875rem solid rgb(var(--danger-rgb))}

.btn-border-top.btn-dark-light {
    border-block-start: .1875rem solid var(--primary-color)
}

.btn-border-top.btn-teal-light {
    border-block-start:.1875rem solid rgb(var(--teal-rgb))}

.btn-border-top.btn-purple-light {
    border-block-start: .1875rem solid rgb(var(--purple-rgb))
}

.btn-facebook {
    background-color: #3b5998;
    color: #fff;
    border: 1px solid #3b5998
}

.btn-facebook:hover,.btn-facebook:focus,.btn-facebook:active {
    background-color: #3b5998!important;
    color: #fff!important;
    border: 1px solid #3b5998!important
}

.btn-google {
    background-color: #cf4e43;
    color: #fff;
    border: 1px solid #CF4E43
}

.btn-google:hover,.btn-google:focus,.btn-google:active {
    background-color: #cf4e43!important;
    color: #fff!important;
    border: 1px solid #CF4E43!important
}

.btn-twitter {
    background-color: #00acee;
    color: #fff;
    border: 1px solid #00acee
}

.btn-twitter:hover,.btn-twitter:focus,.btn-twitter:active {
    background-color: #00acee!important;
    color: #fff!important;
    border: 1px solid #00acee!important
}

.btn-github {
    background-color: #333;
    color: #fff;
    border: 1px solid #333
}

.btn-github:hover,.btn-github:focus,.btn-github:active {
    background-color: #333!important;
    color: #fff!important;
    border: 1px solid #333!important
}

.btn-youtube {
    background-color: red;
    color: #fff;
    border: 1px solid #FF0000
}

.btn-youtube:hover,.btn-youtube:focus,.btn-youtube:active {
    background-color: red!important;
    color: #fff!important;
    border: 1px solid #FF0000!important
}

.btn-instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -webkit-linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f09433",endColorstr="#bc1888",GradientType=1);
    color: #fff;
    border: 1px solid transparent
}

.btn-instagram:hover,.btn-instagram:focus,.btn-instagram:active {
    color: #fff;
    border: 1px solid transparent
}

@media (max-width: 575.98px) {
    .checkbox-button-group .btn-group label,.radio-button-group .btn-group label {
        font-size:.563rem
    }
}

.btn-outline-secondary {
    --bs-btn-hover-color: rgb(var(--secondary-rgb));
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: rgb(var(--secondary-rgb));
    --bs-btn-active-color: rgb(var(--secondary-rgb))
}
