
.main-chart-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 -1.25rem
}

.main-chart-wrapper .chat-content {
    max-height: calc(100vh - 21rem)!important
}

.main-chart-wrapper .chat-info,.main-chart-wrapper .main-chat-area,.main-chart-wrapper .chat-user-details {
    background-color: var(--custom-white);
    height: calc(100vh - 8rem);
    border-radius: .5rem
}

.main-chart-wrapper .chat-users-tab,.main-chart-wrapper .chat-groups-tab,.main-chart-wrapper .chat-calls-tab {
    max-height: calc(100vh - 21rem)
}

.main-chart-wrapper .chat-content {
    max-height: calc(100vh - 17rem)
}

.main-chart-wrapper .chat-content .simplebar-content-wrapper .simplebar-content {
    margin-top: auto
}

.main-chart-wrapper .chat-content ul li {
    margin-block-end:1rem}

.main-chart-wrapper .chat-content ul li:last-child {
    margin-block-end:0}

.main-chart-wrapper .responsive-chat-close,.main-chart-wrapper button.responsive-userinfo-open {
    display: none
}

.main-chart-wrapper .chat-info {
    position: relative
}

.main-chart-wrapper .chat-info .chat-add-icon {
    position: absolute;
    inset-block-end: .75rem;
    inset-inline-end: .8rem;
    z-index: 1;
    box-shadow: 0 0 .5rem rgba(var(--secondary-rgb),.5)
}

.main-chart-wrapper .chat-info .nav-link {
    color: var(--text-muted);
    border-radius: 0
}

.main-chart-wrapper .chat-info .nav-link.active {
    background-color: var(--primary01)
}

.main-chart-wrapper .chat-info .tab-pane {
    padding: 0
}

.main-chart-wrapper .chat-info .chat-groups-tab li {
    padding: .625rem 1.25rem
}

.main-chart-wrapper .chat-info .chat-groups-tab .group-indivudial {
    color: var(--primary-color);
    font-weight: 600
}

.main-chart-wrapper .chat-info .chat-calls-tab li {
    padding: .625rem 1.25rem
}

.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-success i,.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-success i {
    color: rgb(var(--success-rgb));
    font-size: .875rem
}

.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-failed i,.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-failed i {
    color: rgb(var(--danger-rgb));
    font-size: .875rem
}

.main-chart-wrapper .chat-info .chat-users-tab li,.main-chart-wrapper .chat-info .chat-groups-tab li {
    padding: .625rem 1.25rem
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg,.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg {
    color: var(--text-muted);
    max-width: 11.25rem;
    display: inline-block
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-msg,.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-msg {
    color: rgb(var(--success-rgb))
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-read-icon,.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-read-icon {
    display: none
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon,.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon {
    line-height: 1
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon i,.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon i {
    font-size: 1rem;
    color: rgb(var(--success-rgb))
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread,.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-msg,.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-msg {
    color: var(--default-text-color)
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-read-icon i,.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-read-icon i {
    color: var(--text-muted)
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-inactive .chat-read-icon,.main-chart-wrapper .chat-info .chat-groups-tab li.chat-inactive .chat-read-icon {
    display: none
}

.main-chart-wrapper .chat-user-details {
    padding: 1.5rem
}

.main-chart-wrapper .chat-user-details .avatar {
    outline: .25rem solid var(--primary02)
}

.main-chart-wrapper .chat-user-details .shared-files li {
    margin-block-end:1rem}

.main-chart-wrapper .chat-user-details .shared-files li:last-child {
    margin-block-end:0}

.main-chart-wrapper .chat-user-details .shared-files .shared-file-icon i {
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: .3rem;
    border: 1px solid var(--default-border);
    display: flex;
    align-items: center;
    padding: 1.125rem;
    justify-content: center;
    font-size: 1.125rem;
    color: var(--text-muted)
}

.main-chart-wrapper .chat-user-details .chat-media img {
    width: 100%;
    border-radius: .5rem;
    margin-bottom: 1.25rem
}

.main-chart-wrapper .main-chat-area {
    position: relative
}

.main-chart-wrapper .main-chat-area .chat-content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 2.5rem
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info {
    color: var(--default-text-color);
    font-weight: 600;
    font-size: .813rem
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time {
    color: var(--text-muted);
    font-size: .75rem;
    font-weight: 500
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time .chat-read-mark i {
    color: rgb(var(--success-rgb));
    margin-inline-end:.3rem}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div {
    margin-block-start:.4rem;padding: .75rem;
    width: fit-content
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div p {
    font-size: .813rem
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: .5rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .main-chat-msg div {
    background-color: rgb(var(--light-rgb));
    color: var(--default-text-color);
    border-start-start-radius: 0rem;
    border-start-end-radius: .3rem;
    border-end-end-radius: .3rem;
    border-end-start-radius: .3rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .msg-sent-time {
    margin-inline-start:.25rem}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
    justify-content: end;
    text-align: end
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .main-chat-msg div {
    background-color: var(--primary-color);
    color: #fff;
    border-start-start-radius: .3rem;
    border-start-end-radius: 0px;
    border-end-end-radius: .3rem;
    border-end-start-radius: .3rem
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .msg-sent-time {
    margin-inline-end:.25rem}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start,.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
    display: flex
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .chat-list-inner,.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .chat-list-inner {
    display: flex;
    align-items: top;
    max-width: 75%
}

.main-chart-wrapper .main-chat-area .chat-footer {
    width: 100%;
    box-shadow: 0 .25rem 1rem #0000001a
}

.main-chart-wrapper .main-chat-area .chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 4.75rem;
    padding: 0 1.5rem;
    border-top: 1px solid var(--default-border);
    background-color: var(--custom-white);
    position: absolute;
    inset-inline-start: auto;
    inset-inline-end: auto;
    inset-block-end: 0
}

.main-chart-wrapper .main-chat-area .chat-day-label {
    text-align: center;
    color: var(--text-muted);
    margin-block-end:2rem;opacity: .6;
    position: relative
}

.main-chart-wrapper .main-chat-area .chat-day-label span {
    padding: .188rem .5rem;
    font-size: .7rem;
    background-color: var(--primary02);
    border-radius: .3rem;
    color: var(--primary-color)
}

@media (min-width: 992px) {
    .main-chart-wrapper .chat-info {
        min-width:21.875rem;
        max-width: 21.875rem
    }
}

.main-chart-wrapper .main-chat-area {
    width: 100%;
    max-width: 100%;
    overflow: hidden
}

.main-chart-wrapper .chat-user-details {
    min-width: 21.875rem;
    max-width: 21.875rem
}

@media (max-width: 575.98px) {
    .main-chart-wrapper .chat-user-details {
        min-width:17.875rem;
        max-width: 17.875rem
    }
}

@media (max-width: 1275.98px) and (min-width: 992px) {
    .main-chart-wrapper .chat-info {
        min-width:21.875rem;
        max-width: 21.875rem
    }

    .main-chart-wrapper .main-chat-area {
        width: 100%;
        max-width: 100%;
        overflow: hidden
    }

    .main-chart-wrapper .chat-user-details {
        display: none
    }
}

@media (max-width: 991.98px) {
    .main-chart-wrapper .chat-info {
        width:100%
    }

    .main-chart-wrapper .main-chat-area {
        /*display: none;*/
        min-width: 100%;
        max-width: 100%
    }

    .main-chart-wrapper .responsive-chat-close {
        display: block
    }
}

@media (max-width: 1400px) {
    .main-chart-wrapper .chat-user-details {
        display:none;
        position: absolute
    }

    .main-chart-wrapper .chat-user-details.open {
        display: block;
        inset-inline-end: 0;
        inset-block-start: .5rem;
        box-shadow: 0 .125rem #0a0a0a0a;
        border-inline-start:1px solid var(--default-border)}

    .main-chart-wrapper button.responsive-userinfo-open {
        display: block
    }
}

@media (max-width: 991.98px) {
    .main-chart-wrapper.responsive-chat-open .chat-info {
        display:none
    }

    .main-chart-wrapper.responsive-chat-open .main-chat-area {
        display: block
    }
}

@media (max-width: 767.98px) {
    .main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
        width:2.5rem;
        height: 2.5rem
    }
}

@media (max-width: 354px) {
    .main-chart-wrapper .chat-calls-tab,.main-chart-wrapper .chat-groups-tab,.main-chart-wrapper .chat-users-tab {
        max-height:calc(100vh - 22rem)
    }
}


.avatar {
    position: relative;
    height: 2.625rem;
    width: 2.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    color: #fff;
    font-weight: 500
}

.avatar a.badge:hover {
    color: #fff
}

.avatar img {
    width: 100%;
    height: 100%;
    border-radius: .5rem
}

.avatar.avatar-rounded,.avatar.avatar-rounded img {
    border-radius: 50%
}

.avatar.avatar-radius-0,.avatar.avatar-radius-0 img {
    border-radius: 0
}

.avatar .avatar-badge {
    position: absolute;
    inset-block-start: -4%;
    inset-inline-end: -.375rem;
    width: 1.4rem;
    height: 1.4rem;
    font-size: .625rem;
    border: 2px solid #ffffff;
    border-radius: 50%!important;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar.online:before,.avatar.offline:before {
    position: absolute;
    content: "";
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    inset-inline-end: 0;
    inset-block-end: 0;
    border: 2px solid var(--custom-white)
}

.avatar.online:before {
    background-color: rgb(var(--success-rgb))
}

.avatar.offline:before {
    background-color: var(--gray-5)
}

.avatar.avatar-xs {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: .65rem
}

.avatar.avatar-xs .avatar-badge {
    padding: .25rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    font-size: .5rem;
    inset-block-start: -25%;
    inset-inline-end: -.5rem
}

.avatar.avatar-sm {
    width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: .65rem
}

.avatar.avatar-sm .avatar-badge {
    padding: .3rem;
    width: 1.1rem;
    height: 1.1rem;
    line-height: 1.1rem;
    font-size: .5rem;
    inset-block-start: -38%;
    inset-inline-end: -.5rem
}

.avatar.avatar-sm.online:before,.avatar.avatar-sm.offline:before {
    width: .5rem;
    height: .5rem
}

.avatar.avatar-md {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: .8rem
}

.avatar.avatar-md .avatar-badge {
    padding: .4rem;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: .65rem;
    inset-block-start: -6%;
    inset-inline-end: -13%
}

.avatar.avatar-md.online:before,.avatar.avatar-md.offline:before {
    width: .75rem;
    height: .75rem
}

.avatar.avatar-md svg {
    width: 1.5rem;
    height: 1.5rem
}

.avatar.avatar-lg {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem
}

.avatar.avatar-lg .avatar-badge {
    inset-block-start: -15%;
    inset-inline-end: -.25%
}

.avatar.avatar-lg.online:before,.avatar.avatar-lg.offline:before {
    width: .8rem;
    height: .8rem
}

.avatar.avatar-lg svg {
    width: 1.8rem;
    height: 1.8rem
}

.avatar.avatar-xl {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.25rem
}

.avatar.avatar-xl .avatar-badge {
    inset-block-start: -8%;
    inset-inline-end: -.2%
}

.avatar.avatar-xl.online:before,.avatar.avatar-xl.offline:before {
    width: .95rem;
    height: .95rem
}

.avatar.avatar-xxl {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    font-size: 1.5rem
}

.avatar.avatar-xxl .avatar-badge {
    inset-block-start: -4%;
    inset-inline-end: 0rem
}

.avatar.avatar-xxl.online:before,.avatar.avatar-xxl.offline:before {
    width: 1.05rem;
    height: 1.05rem;
    inset-block-end: .25rem
}

.avatar-list-stacked {
    padding: 0
}

.avatar-list-stacked .avatar {
    margin-inline-end:-.65rem!important;border: 1px solid rgba(0,0,0,.05);
    vertical-align: middle;
    transition: transform ease .2s
}

.avatar-list-stacked .avatar:last-child {
    margin-inline-end:0!important}

.avatar-list-stacked .avatar:hover {
    z-index: 1;
    transform: translateY(-.188rem)
}

[dir=rtl] .avatar-list-stacked .ri-arrow-right-s-line {
    transform: rotate(180deg)
}

