

.accordion-button {
    background-color: var(--custom-white);
    color: var(--default-text-color);
    padding: .75rem 1rem;
    font-size: .85rem;
    font-weight: 500
}

.accordion-button:not(.collapsed) {
    color: var(--default-text-color);
    background-color: var(--default-background);
    box-shadow: none
}

.accordion-button:focus {
    border-color: var(--primary03);
    box-shadow: none
}

.accordion-button:after,.accordion-button:not(.collapsed):after {
    background-image: none
}

.accordion-button:after {
    content: "^";
    font-family:  Line Awesome Free !important;
    font-size: 1rem;
    line-height: 1;
    background-color: var(--default-background);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3.125rem;
    color: var(--default-text-color)
}

.accordion-body {
    padding: .75rem 1rem;
    font-size: .8125rem;
    color: var(--text-muted)
}

.accordion-item {
    color: var(--default-text-color);
    background-color: var(--custom-white);
    border: 1px solid var(--default-border)
}

.accordions-items-seperate .accordion-item:not(:first-of-type) {
    margin-block-start:.5rem}

.accordions-items-seperate .accordion-item {
    border-block-start:1px solid var(--default-border);border-radius: .5rem;
    overflow: hidden
}

.accordion-item:last-of-type {
    border-bottom-right-radius: .35rem;
    border-bottom-left-radius: .35rem
}

.accordion-item:first-of-type {
    border-top-left-radius: .35rem;
    border-top-right-radius: .35rem
}

.accordion.accordion-primary .accordion-button {
    background-color: var(--primary01);
    color: var(--primary-color)
}

.accordion.accordion-primary .accordion-button:after {
    background-color: var(--primary-color);
    color: #fff
}

.accordion.accordion-primary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-primary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-secondary .accordion-button {
    background-color: rgba(var(--secondary-rgb),.1);
    color: rgb(var(--secondary-rgb))
}

.accordion.accordion-secondary .accordion-button:after {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.accordion.accordion-secondary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-secondary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-success .accordion-button {
    background-color: rgba(var(--success-rgb),.1);
    color: rgb(var(--success-rgb))
}

.accordion.accordion-success .accordion-button:after {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.accordion.accordion-success .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-success .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-danger .accordion-button {
    background-color: rgba(var(--danger-rgb),.1);
    color: rgb(var(--danger-rgb))
}

.accordion.accordion-danger .accordion-button:after {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.accordion.accordion-danger .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-danger .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-warning .accordion-button {
    background-color: rgba(var(--warning-rgb),.1);
    color: rgb(var(--warning-rgb))
}

.accordion.accordion-warning .accordion-button:after {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.accordion.accordion-warning .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-warning .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-info .accordion-button {
    background-color: rgba(var(--info-rgb),.1);
    color: rgb(var(--info-rgb))
}

.accordion.accordion-info .accordion-button:after {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.accordion.accordion-info .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-info .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-primary .accordion-button {
    background-color: var(--primary-color);
    color: #fff
}

.accordion.accordion-solid-primary .accordion-button:after {
    background-color: var(--custom-white);
    color: var(--primary-color)
}

.accordion.accordion-solid-primary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-primary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-secondary .accordion-button {
    background-color: rgb(var(--secondary-rgb));
    color: #fff
}

.accordion.accordion-solid-secondary .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb))
}

.accordion.accordion-solid-secondary .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-secondary .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-success .accordion-button {
    background-color: rgb(var(--success-rgb));
    color: #fff
}

.accordion.accordion-solid-success .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb))
}

.accordion.accordion-solid-success .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-success .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-danger .accordion-button {
    background-color: rgb(var(--danger-rgb));
    color: #fff
}

.accordion.accordion-solid-danger .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb))
}

.accordion.accordion-solid-danger .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-danger .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-warning .accordion-button {
    background-color: rgb(var(--warning-rgb));
    color: #fff
}

.accordion.accordion-solid-warning .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb))
}

.accordion.accordion-solid-warning .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-warning .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-solid-info .accordion-button {
    background-color: rgb(var(--info-rgb));
    color: #fff
}

.accordion.accordion-solid-info .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb))
}

.accordion.accordion-solid-info .accordion-button.collapsed {
    background-color: var(--custom-white);
    color: var(--default-text-color)
}

.accordion.accordion-solid-info .accordion-button.collapsed:after {
    background-color: var(--default-background);
    color: var(--default-text-color)
}

.accordion.accordion-border-primary .accordion-item {
    border: 1px solid var(--primary-color)
}

.accordion.accordion-border-primary .accordion-button {
    background-color: var(--custom-white);
    color: var(--primary-color);
    border-block-end:0px}

.accordion.accordion-border-primary .accordion-button:after {
    background-color: var(--custom-white);
    color: var(--primary-color);
    border: 1px solid var(--primary-color)
}

.accordion.accordion-border-primary .accordion-button:not(.collapsed) {
    border-block-end:1px solid var(--primary-color)}

.accordion.accordion-border-secondary .accordion-item {
    border: 1px solid rgb(var(--secondary-rgb))
}

.accordion.accordion-border-secondary .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb));
    border-block-end:0px}

.accordion.accordion-border-secondary .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--secondary-rgb));
    border: 1px solid rgb(var(--secondary-rgb))
}

.accordion.accordion-border-secondary .accordion-button:not(.collapsed) {
    border-block-end:1px solid rgb(var(--secondary-rgb))}

.accordion.accordion-border-success .accordion-item {
    border: 1px solid rgb(var(--success-rgb))
}

.accordion.accordion-border-success .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb));
    border-block-end:0px}

.accordion.accordion-border-success .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--success-rgb));
    border: 1px solid rgb(var(--success-rgb))
}

.accordion.accordion-border-success .accordion-button:not(.collapsed) {
    border-block-end:1px solid rgb(var(--success-rgb))}

.accordion.accordion-border-danger .accordion-item {
    border: 1px solid rgb(var(--danger-rgb))
}

.accordion.accordion-border-danger .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb));
    border-block-end:0px}

.accordion.accordion-border-danger .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--danger-rgb));
    border: 1px solid rgb(var(--danger-rgb))
}

.accordion.accordion-border-danger .accordion-button:not(.collapsed) {
    border-block-end:1px solid rgb(var(--danger-rgb))}

.accordion.accordion-border-warning .accordion-item {
    border: 1px solid rgb(var(--warning-rgb))
}

.accordion.accordion-border-warning .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb));
    border-block-end:0px}

.accordion.accordion-border-warning .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--warning-rgb));
    border: 1px solid rgb(var(--warning-rgb))
}

.accordion.accordion-border-warning .accordion-button:not(.collapsed) {
    border-block-end:1px solid rgb(var(--warning-rgb))}

.accordion.accordion-border-info .accordion-item {
    border: 1px solid rgb(var(--info-rgb))
}

.accordion.accordion-border-info .accordion-button {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb));
    border-block-end:0px}

.accordion.accordion-border-info .accordion-button:after {
    background-color: var(--custom-white);
    color: rgb(var(--info-rgb));
    border: 1px solid rgb(var(--info-rgb))
}

.accordion.accordion-border-info .accordion-button:not(.collapsed) {
    border-block-end:1px solid rgb(var(--info-rgb))}

.accordion.accordionicon-none .accordion-button:after {
    display:none
}

.accordion.accordionicon-left .accordion-button {
    padding-inline-start:2.5rem}

.accordion.accordionicon-left .accordion-button:after {
    position: absolute;
    inset-inline-start: .625rem
}



.customized-accordion .accordion-item.custom-accordion-primary .accordion-button {
    background-color: var(--primary01);
    border-inline-start:.25rem solid var(--primary06)}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-button:after {
    background-color:var(--primary06);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-body {
    background-color: var(--primary01);
    padding-block-start:0;border-inline-start:.25rem solid var(--primary06)}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button {
    background-color: rgba(var(--secondary-rgb),.1);
    border-inline-start:.25rem solid rgba(var(--secondary-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button:after {
    background-color:rgba(var(--secondary-rgb),.6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-body {
    background-color: rgba(var(--secondary-rgb),.1);
    padding-block-start:0;border-inline-start:.25rem solid rgba(var(--secondary-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button {
    background-color: rgba(var(--warning-rgb),.1);
    border-inline-start:.25rem solid rgba(var(--warning-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button:after {
    background-color:rgba(var(--warning-rgb),.6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-body {
    background-color: rgba(var(--warning-rgb),.1);
    padding-block-start:0;border-inline-start:.25rem solid rgba(var(--warning-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button {
    background-color: rgba(var(--info-rgb),.1);
    border-inline-start:.25rem solid rgba(var(--info-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button:after {
    background-color:rgba(var(--info-rgb),.6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-body {
    background-color: rgba(var(--info-rgb),.1);
    padding-block-start:0;border-inline-start:.25rem solid rgba(var(--info-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button {
    background-color: rgba(var(--success-rgb),.1);
    border-inline-start:.25rem solid rgba(var(--success-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button:after {
    background-color:rgba(var(--success-rgb),.6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-body {
    background-color: rgba(var(--success-rgb),.1);
    padding-block-start:0;border-inline-start:.25rem solid rgba(var(--success-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button {
    background-color: rgba(var(--danger-rgb),.1);
    border-inline-start:.25rem solid rgba(var(--danger-rgb),.6)}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button:after {
    background-color:rgba(var(--danger-rgb),.6);
    color: #fff
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-body {
    background-color: rgba(var(--danger-rgb),.1);
    padding-block-start:0;border-inline-start:.25rem solid rgba(var(--danger-rgb),.6)}

